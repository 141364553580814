import React, {Component} from 'react';
import Index from './Component/Index';

 
class App extends Component {
  render(){
    return (
      <div className="App">
          <Index/>   
      </div>
    );
  } 
}
 
export default App;
